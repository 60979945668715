@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.scss';

.booking-list {
  h2 {
    color: $color-white;
    font-size: 14px;
    margin: 20px 0;
  }

  h3 {
    color: $color-white;
    font-size: 14px;
    margin: 0;
  }

  .heading {
    color: $color-white;
    font-size: 14px;
    margin-bottom: 20px;

    &:first-of-type {
      margin-top: 10px;
    }
  }

  .filters {
    align-items: center;
    border-bottom: 1px solid #ffffff60;
    display: flex;
    padding: 10px 0 20px;
    margin: 0;

    & > *:not(:last-child) {
      margin-right: 15px;
    }

    .reset {
      color: $color-white;
      cursor: pointer;
      font-size: 12px;
      flex-shrink: 0;
      margin-bottom: 4px;
      margin-right: auto;
      text-decoration: underline;
      width: max-content;
    }

    .btn-export-data {
      flex-shrink: 0;
      width: max-content;

      &:disabled {
        background-color: darken(#11db30, 20%);
        color: $color-white;
      }
    }
  }

  .table {
    margin-bottom: 20px;

    tr {
      th {
        padding: 12px 10px !important;
      }

      td.ant-table-cell {
        padding: 12px 10px !important;

        &:first-child {
          width: 85px;
        }

        &:nth-child(2) {
          max-width: 110px;
        }

        &:nth-child(3) {
          max-width: 120px;
        }

        &:nth-child(4) {
          max-width: 110px;
        }

        &:nth-child(5) {
          max-width: 95px;
        }

        &:nth-child(6) {
          width: 85px;
        }

        &:nth-child(7) {
          max-width: 85px;
        }

        &:nth-child(8) {
          width: 85px;
        }

        &:nth-child(9) {
          max-width: 60px;
        }

        &:nth-child(10) {
          max-width: 144px;
        }
      }
    }

    .ant-table-cell {
      &.ant-table-row-expand-icon-cell {
        vertical-align: middle;

        .icon-expand,
        .icon-expanded {
          cursor: pointer;
          height: 20px;
          width: 20px;
          transition: transform 0.5s;

          svg {
            fill: $color-white;
            height: 20px;
            width: 20px;
          }
        }

        .icon-expanded {
          transform: rotate(180deg);
        }
      }
    }

    .btn {
      font-size: 12px;
      height: 24px;
      min-width: 130px;
      padding: 6px !important;
      position: relative;

      &::after {
        content: '';
        border: solid $color-white;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transform: translateY(-70%) rotate(45deg);
        position: absolute;
        right: 7px;
        top: 50%;
      }

      &:hover {
        color: $color-white;
      }

      &.btn-cancelled {
        background-color: $color-grey-dark-3;
        border-color: $color-red-dark-1;

        &:hover {
          background-color: lighten($color-grey-dark-3, 10%);
          border-color: lighten($color-red-dark-1, 10%);
        }
      }

      &.btn-completed {
        @include btn-color($color-green-grass);
      }

      &.btn-default {
        @include btn-color($color-grey-middle-1);
        text-transform: capitalize;
      }

      &.btn-in-progress {
        background-color: $color-grey-dark-3;
        border-color: $color-yellow;

        &:hover {
          background-color: lighten($color-grey-dark-3, 10%);
          border-color: lighten($color-yellow, 10%);
        }
      }

      &.btn-not-processed {
        @include btn-color($color-red-dark-1);
      }
    }

    .details-link {
      text-decoration: underline;
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
  }
}

.menu-item {
  background-color: $color-grey-dark-3;
  color: $color-white !important;
  font-size: 12px !important;

  &:hover {
    background-color: lighten($color-grey-dark-3, 10%) !important;
  }
}
