@import './variables.scss';

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  font: inherit;
  position: relative;
  margin: 30px auto 0;
  width: 1180px;
}

.row {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.flex-row {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.shadow {
  box-shadow: inset 0px -1px 1px -1px #000;
}

.ant-spin-nested-loading {
  width: 100%;

  > div > .ant-spin-lg {
    .ant-spin-dot {
      margin: 0;
    }
  }
}

.ant-btn-primary.btn-search {
  &[disabled] {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff;
    opacity: 0.5;
  }
}

.ant-btn-link {
  padding: 0 5px;
  outline: none;
}

// macOS
a {
  cursor: pointer;
}

// hbh-ui-modules
.dropdown {
  cursor: pointer;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  min-width: 130px;
  z-index: 2;

  .label {
    color: $color-white;
    cursor: pointer;
    display: block;
    font-size: 12px;
    line-height: 1.4;
    margin-bottom: 10px;
  }

  .rs {
    &__control {
      background: transparent;
      border: 1px solid $color-white;
      box-shadow: none;
      min-height: 30px;
    }

    &__indicator {
      padding: 0;
      margin-right: 8px;
      svg {
        fill: $color-white;
        height: 16px;
        width: 16px;
      }
    }

    &__value-container {
      color: $color-white;
      padding: 0 6px;
    }

    &__single-value,
    &__placeholder {
      color: $color-white;
    }

    &__menu {
      background-color: #ffffff;
      border: 1px solid $color-white;
      box-shadow: none;
      color: $color-grey-dark-2;
      overflow: hidden;

      &-list {
        padding: 0;
      }
    }

    &__option {
      cursor: pointer;

      &--is-focused {
        background-color: #f2f7ff20;
      }

      &--is-selected {
        background-color: $color-blue-primary;
      }
    }
  }
}
