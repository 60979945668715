@import '@assets/styles/variables.scss';

.select {
  font-size: 12px;
  font-family: $font-default;
  height: 100%;
  width: 100%;

  .rs {
    &__control {
      background: transparent;
      border: 1px solid $color-grey-light-2;
      border-radius: 0;
      box-shadow: none;
      cursor: pointer;
      min-height: 30px;

      &:hover {
        border: 1px solid $color-grey-light-2;
      }
    }

    &__indicator {
      padding: 0;
      margin-right: 8px;

      svg {
        fill: $color-grey-middle-1;
        width: 17px;
        height: 17px;
      }
    }

    &__indicator-separator {
      margin-right: 8px;
    }

    &__single-value {
      color: $color-grey-dark-2;
      line-break: 17px;
    }

    &__value-container {
      padding: 1px 4px;
    }

    &__menu {
      color: $color-grey-dark-2;
      overflow: hidden;
      z-index: 5;

      &-list {
        padding: 0;
      }
    }

    &__option {
      cursor: pointer;

      &--is-focused {
        background-color: #f2f7ff20;
      }

      &--is-selected {
        background-color: $color-blue-primary;
      }
    }

    &__control--menu-is-open .rs__dropdown-indicator {
      transform: rotate(180deg);
    }
  }
}

.select-label {
  color: $color-grey-dark-1;
  cursor: pointer;
  display: block;
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 8px;
}

.select-error {
  color: $color-red-light-1;
  font-size: 9px;
  margin: 0;
  position: absolute;
}
