@import '@assets/styles/variables.scss';

.date-picker-container {
  position: relative;
  width: 130px;
  z-index: 2;

  .field {
    background: transparent;
    box-sizing: border-box;
    border: 1px solid $color-white;
    color: $color-white;
    cursor: pointer;
    font-size: 12px;
    height: auto;
    line-height: 1.4;
    min-height: 30px;
    padding: 0 8px;
    outline: 0;
    width: 100%;

    &::placeholder {
      color: $color-white;
    }
  }

  .label {
    color: $color-grey-middle-1;
    cursor: pointer;
    display: block;
    font-size: 12px;
    line-height: 1.4;
    margin-bottom: 10px;
  }

  .react-datepicker__input-container {
    position: relative;

    &:after {
      background-image: url('../../assets/svg/icon_calendar.svg');
      background-size: cover;
      content: '';
      display: inline-block;
      fill: $color-grey-middle-1;
      height: 16px;
      width: 15px;
      opacity: 0.9;
      position: absolute;
      right: 8px;
      top: 50%;
      margin-top: -8px;
      z-index: 1;
    }
  }
}
