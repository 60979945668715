@import '@assets/styles/common.scss';

.input-field {
  input {
    box-sizing: border-box;
    border: 1px solid $color-grey-light-2;
    color: $color-grey-dark-2;
    font-size: 12px;
    line-height: 0;
    min-height: 30px;
    padding: 6px;
    outline: 0;
    transition: 0.3s background-color linear;
    width: 100%;

    &:focus {
      background-color: transparent;
      border: 1px solid $color-grey-light-2;
    }

    &:disabled {
      background-color: #9b9b9b20 !important;
      border: 1px solid #9b9b9b20 !important;
      color: $color-grey-middle-1 !important;
      cursor: default !important;
    }
  }
}

.input-label {
  color: $color-grey-dark-1;
  cursor: pointer;
  display: block;
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 10px;
}

.input-error {
  color: $color-red-light-1;
  font-size: 9px;
  margin: 0;
  position: absolute;
}
