@import '@assets/styles/mixins.scss';

.maintenance-disclaimer {
  background-color: #005d88e6;
  font-size: 14px;
  color: #fff;
  padding: 10px;
  max-width: 500px;
  margin: 100px auto -100px;
}

.btn-requests {
  @include btn-color(#c9c101);
}
