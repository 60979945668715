@import '@assets/styles/common.scss';

.modal {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  position: fixed;
  top: 0;
  transition: all ease-in-out;
  width: 100%;
  z-index: 99;

  &-bg {
    background-color: rgba(36, 36, 36, 0.8);
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &-content {
    background-color: $color-white;
    min-width: 460px;
    z-index: 999;
  }

  &-header {
    @extend .flex-row;
    background-color: $color-blue-primary;
    color: $color-white;
    min-height: 40px;
    padding: 0 15px;
    width: 100%;
  }

  &-btn-close {
    cursor: pointer;
    margin-left: auto;
  }
}
