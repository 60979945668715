@import '@assets/styles/variables.scss';

.page-access {
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
  padding: 30px;
  position: relative;
  width: 100vw;

  .container {
    color: $color-black;
    margin-top: 15vh;
    margin-left: 10vw;
    width: 800px;

    .message {
      font-size: 36px;
    }
  }
}
