@import '@assets/styles/variables.scss';

.page-callback {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  &:after {
    content: '';
    background-color: $color-black;
    opacity: 0.1;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
}
