@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.scss';

.booking-details-title {
  margin-top: 40px !important;
  margin-left: 12px !important;
}

.booking-details-container {
  background-color: $color-grey-middle-2;
  border-radius: $border-radius-small;
  color: $color-white;
  padding: 20px 40px;
  margin: 0 auto 40px !important;
  min-height: 400px;
  width: 1080px !important;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    h3 {
      color: $color-white;
      font-size: 16px;
      font-weight: bold;
    }

    > div {
      display: flex;
      justify-content: right;
      margin-left: auto;
      width: 32%;
    }
  }

  .btn {
    min-width: 140px;
    height: 30px;
    padding: 6px 30px;
    color: $color-white;
    font-size: 14px;

    &:hover {
      color: $color-white !important;
    }

    &.btn-cancelled {
      background-color: $color-grey-dark-3;
      border-color: $color-red-dark-1;
      cursor: default;

      &:hover {
        background-color: lighten($color-grey-dark-3, 10%);
        border-color: lighten($color-red-dark-1, 10%);
      }
    }

    &.btn-completed {
      @include btn-color($color-green-grass);
      cursor: default;
    }

    &.btn-default {
      @include btn-color($color-grey-middle-1);
      cursor: default;
      text-transform: capitalize;
    }

    &.btn-in-progress {
      background-color: $color-grey-dark-4;
      border-color: $color-yellow;
      cursor: default;

      &:hover {
        background-color: lighten($color-grey-dark-4, 10%);
        border-color: lighten($color-yellow, 10%);
      }
    }

    &.btn-not-processed {
      @include btn-color($color-red-dark-1);
      cursor: default;
    }

    &.btn-print {
      margin-right: 1em;

      &:hover {
        color: $color-black !important;
      }
    }
  }

  .asset-details {
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 25px;

    .row-1-col-1 {
      grid-area: 1 / 1 / 1 / 4;
    }

    .row-1-col-2 {
      grid-area: 1 / 4 / 1 / 7;
    }

    .row-1-col-3 {
      grid-area: 1 / 7 / 1 / 12;
    }

    .row-2-col-1 {
      grid-area: 2 / 1 / 2 / 4;
    }

    .row-2-col-2 {
      grid-area: 2 / 4 / 2 / 7;
    }

    .row-2-col-3 {
      grid-area: 2 / 7 / 2 / 12;
    }

    .row-3-col-1 {
      grid-area: 3 / 1 / 3 / 6;
    }

    .row-3-col-2 {
      grid-area: 3 / 6 / 3 / 9;
    }

    .row-3-col-3 {
      grid-area: 3 / 9 / 3 / 12;
    }

    .row-4-col-1 {
      grid-area: 4 / 1 / 4 / 4;
    }

    .row-4-col-2 {
      grid-area: 4 / 4 / 4 / 7;
    }

    .row-4-col-3 {
      grid-area: 4 / 7 / 4 / 12;
    }

    .line-break {
      .hbh-details-item-value {
        overflow: inherit;
        text-overflow: inherit;
        white-space: inherit;
      }
    }
  }

  .leg-details {
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 25px;

    .hbh-details-item-label {
      font-size: 11px !important;
    }

    > div {
      padding: 6px 16px;
    }

    .departure-date {
      grid-area: 1 / 3 / 1 / 5;
    }

    .departure-time {
      grid-area: 1 / 5 / 1 / 7;
    }

    .departure-country {
      grid-area: 1 / 7 / 1 / 9;
    }

    .departure-city {
      grid-area: 1 / 9 / 1 / 11;
    }

    .arrival-date {
      grid-area: 2 / 3 / 2 / 5;
    }

    .arrival-time {
      grid-area: 2 / 5 / 2 / 7;
    }

    .arrival-country {
      grid-area: 2 / 7 / 2 / 9;
    }

    .arrival-city {
      grid-area: 2 / 9 / 2 / 11;
    }

    .transport-support {
      .hbh-details-item-label {
        margin-bottom: 0;
      }
    }

    .travel-info {
      grid-area: 1 / 1 / 3 / 3;

      .info {
        display: flex;
        flex-direction: column;

        h4 {
          color: $color-grey-middle-1;
          font-size: 12px;
          margin: 12px 0;
          text-transform: uppercase;
        }

        .hbh-details-item-value {
          flex: 1;
          color: $color-grey-middle-3;
        }

        .travel-type {
          font-size: 16px;
          text-transform: uppercase;
        }

        .transport {
          font-size: 14px;
        }
      }
    }
  }

  .footer {
    align-items: center;
    background-color: $color-grey-dark-3;
    border: 1px solid $color-grey-dark-4;
    border-radius: 4px;
    box-shadow: inset 0px -1px 1px -1px $color-black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 12px 18px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .btn-back {
      background-color: $color-red-light-1;
      border-color: $color-red-light-1;
      margin-left: 12px;

      &:hover {
        background-color: lighten($color-red-light-1, 10%);
        border-color: lighten($color-red-light-1, 10%);
      }
    }
  }

  .booking-route {
    position: relative;

    .btn-edit-leg {
      position: absolute;
      right: 0;
      top: -4px;
    }
  }
}

@media print {
  .booking-details-container {
    background-color: $color-white;

    .title {
      h3 {
        color: $color-black;
        text-transform: uppercase;
      }
    }

    .btn-print,
    .btn-edit-leg {
      display: none !important;
    }

    .footer {
      display: none;
    }

    .hbh-details-title {
      color: $color-black;
    }

    .asset-details,
    .leg-details {
      .hbh-details-item {
        background-color: transparent;
      }

      .hbh-details-item-label,
      .hbh-details-item-value {
        color: $color-black;
      }

      .travel-info {
        .info {
          h4 {
            color: $color-black;
          }

          .travel-type {
            color: $color-black;
            font-size: 16px;
            text-transform: uppercase;
          }

          .transport {
            color: $color-black;
            font-size: 14px;
          }
        }
      }
    }
  }
}
