// Main
$color-black: #000;
$color-blue: #0057b7;
$color-white: #fff;
$color-yellow: #ffdd00;

// Colors
$color-blue-primary: #0288ff;
$color-blue-dark-1: #08558b;
$color-green-grass: #0dac26;
$color-grey-dark-1: #3f424e;
$color-grey-dark-2: #434a4e;
$color-grey-dark-3: #262829;
$color-grey-dark-4: #161616;
$color-grey-light-1: #ebebeb;
$color-grey-light-2: #98a6b5;
$color-grey-middle-1: #6e7c8b;
$color-grey-middle-2: #333536;
$color-grey-middle-3: #4e4e4e;
$color-cyan: #0adbbb;
$color-red-dark-1: #b3262b;
$color-red-light-1: #ff6666;

// Fonts
$font-default: 'OpenSans', 'Helvetica Neue', sans-serif;

// Miscellaneous
$border-radius-small: 4px;
