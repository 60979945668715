@import '@assets/styles/variables.scss';

.page-not-found {
  background: url('../../assets/svg/logo.svg') no-repeat;
  background-attachment: fixed;
  background-color: $color-blue-dark-1;
  background-position: bottom 30% right 5%;
  background-size: 380px;
  min-height: calc(100vh - 125px);
  padding: 0 5%;

  .container {
    margin-top: 120px;
    max-width: 440px;
    position: relative;
    transition: left 0.5s ease-out, opacity 0.5s ease-out;

    h1 {
      color: $color-white;
      font-size: 36px;
      line-height: 46px;
    }

    hr {
      background-color: rgba(255, 255, 255, 0.2);
      border: 0;
      height: 1px;
      margin: 35px 0;
    }

    p {
      color: $color-white;
      font-size: 18px;
      line-height: 27px;
      transition: color 0.2s linear;
    }

    .links {
      display: flex;
      margin-top: 45px;

      .link {
        border: 2px solid rgba(255, 255, 0, 1) !important;
        border-radius: 50px;
        color: rgba(255, 255, 0, 1) !important;
        display: inline-block;
        font-size: 14px;
        padding: 8px 30px 9px;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.2s linear, border-color 0.2s linear;

        &:first-child {
          margin-right: 25px;
        }
      }
    }
  }
}
