@import '@assets/styles/variables.scss';

.date-picker-field {
  position: relative;

  .date-picker {
    background: transparent;
    box-sizing: border-box;
    border: 1px solid $color-grey-light-2;
    color: $color-grey-dark-2;
    cursor: pointer;
    font-size: 12px;
    height: auto;
    line-height: 1.4;
    min-height: 30px;
    padding: 0 8px;
    outline: 0;
    width: 100%;

    &::placeholder {
      color: $color-grey-dark-2;
    }
  }

  .date-picker-label {
    color: $color-grey-dark-1;
    cursor: pointer;
    display: block;
    font-size: 12px;
    line-height: 1.4;
    margin-bottom: 10px;
  }

  .date-picker-error {
    color: $color-red-light-1;
    font-size: 9px;
    margin: 0;
    position: absolute;
  }
}
