@import '@assets/styles/variables.scss';

.page-redirect {
  align-items: center;
  background-color: $color-blue;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  .redirect-box {
    display: flex;
    flex-direction: column;
    width: 800px;

    h3 {
      color: $color-white;
      font-size: 22px;
      margin-bottom: 30px;
    }

    a {
      color: $color-yellow;
      font-size: 18px;
      text-align: center;
    }
  }
}
