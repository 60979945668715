.form {
  min-width: 700px;
  padding: 15px;

  .row {
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  h3 {
    font-size: 14px;
    padding-bottom: 6px;
    white-space: nowrap;
  }

  .field {
    width: 150px;

    &.transport-support {
      margin-bottom: 5px;
      margin-left: 25px;
    }
  }

  .btn-submit {
    margin-left: auto;
  }
}
