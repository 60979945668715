@import '@assets/styles/variables.scss';

.switch {
  font-family: $font-default;

  &-label {
    color: $color-grey-dark-1;
    cursor: pointer;
    display: block;
    font-size: 12px;
    line-height: 1.4;
    margin-bottom: 8px;
  }

  &-text {
    font-size: 16px;
    margin-left: 10px;
  }

  .ant-switch {
    background-color: $color-grey-middle-1;
  }

  .ant-switch-checked {
    background-color: $color-blue-primary;
  }
}
